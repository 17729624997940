import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import classNames from "classnames";

import { useAppDispatch } from "../../redux";
import {
  assignTemplateToKlaviyoCampaignAction,
  createKlaviyoCampaignAction,
  estimateRecipientsAction,
  getKlaviyoTemplatesAction,
} from "../../redux/dashboard/klaviyo-slice";

import Button from "../button";
import DropDown from "../dropdown";
import SectionHeader from "../section-header";
import Table from "../table";

import Icon from "../../assets/svg";
import { insertDivAsFirstChild } from "../../utils/functions";

interface SelectedTemplate {
  templateId: string;
  name: string;
  editor_type: string;
  html: string;
  text: string;
  created: string;
  updated: string;
}

type Props = {
  isOpen: boolean;
  close: any;
};

const KlaviyoTemplateModal = ({ isOpen, close }: Props) => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] =
    useState<SelectedTemplate | null>(null);

  const {
    klaviyoTemplates,
    klaviyoCampaign,
    isFetchingKlaviyoTemplates,
    isCreatingKlaviyoCampaign,
    isAssigningTemplateToKlaviyoCampaign,
  } = useSelector((state: any) => state.klaviyoSlice);

  const createdCampaign = JSON.parse(
    localStorage.getItem("__created_campaign") || "{}"
  );

  console.log("klaviyoCampaign", klaviyoCampaign);

  console.log("campaignId:", klaviyoCampaign?.campaignId);
  console.log("campaignMessageId:", klaviyoCampaign?.campaignMessageId);

  const tableHeaders = ["Name", "", ""];
  const contentOptions = [
    {
      label: "Preview",
      action: (_id: string) => {
        setStep(2);
      },
    },
    // {
    //   label: "Use template",
    //   action: (templateId: string) =>
    //     assignTemplateToKlaviyoCampaign(templateId),
    // },
  ];

  useEffect(() => {
    if (step === 1) getKlaviyoTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const getKlaviyoTemplates = () => {
    dispatch(getKlaviyoTemplatesAction()).then((res: any) => {
      if (res?.success) console.log("res:", res);
    });
  };

  const createKlaviyoCampaign = () => {
    // const recipientEmails = extractEmails(
    //   JSON.parse(createdCampaign?.campaign_recipients)
    // );

    const campaignData = {
      business_slug: createdCampaign?.slug,
      // campaignId: createdCampaign?.id,
      campaignId: createdCampaign?.id,
      recipients: [
        "okolieuchenna890@gmail.com",
        "deji.devcloud@gmail.com",
        "uwa.ojo@gmail.com",
        "Fauzeeya.nene@gmail.com",
        "Idowuadetoun@gmail.com",
      ],
      // recipients: recipientEmails,
      email_label: createdCampaign?.campaign_title,
      email_subject: createdCampaign?.campaign_title,
      email_preview: createdCampaign?.campaign_subject,
    };

    console.log("campaignData", campaignData.recipients);
    dispatch(createKlaviyoCampaignAction(campaignData))
      .then((res: any) => {
        if (res?.success === true) {
          // localStorage.removeItem("__created_campaign");
          setStep((prev) => prev + 1);
        }
      })
      .catch((error: any) => {
        console.log("error:", error);
      });
  };

  const assignTemplateToKlaviyoCampaign = async (templateId: string) => {
    const campaignMessageId = klaviyoCampaign?.campaignMessageId;

    console.log("campaignMessageId", campaignMessageId);

    const assignData = {
      data: {
        type: "campaign-message",
        id: campaignMessageId,
        relationships: {
          template: {
            data: {
              type: "template",
              id: templateId,
            },
          },
        },
      },
    };
    const res = await dispatch(
      assignTemplateToKlaviyoCampaignAction(assignData)
    );
    if (res?.success === true) {
      await dispatch(estimateRecipientsAction(klaviyoCampaign?.campaignId));
      if (res?.success === true) {
        localStorage.setItem(
          "klaviyo_campaign_id",
          klaviyoCampaign?.campaignId
        );
        close();
      }
    }
  };

  return (
    <Modal
      className={classNames("klaviyo_template_modal", {
        template_large: step === 2,
      })}
      isOpen={isOpen}
    >
      <div className="modal_header">
        <SectionHeader
          leftSide={
            <div className="header_box">
              <p className="header_title">
                {
                  [
                    "Proceed with Klaviyo Campaign",
                    "Preview Template",
                    "Pick a klaviyo template",
                  ][step]
                }
              </p>
              <p className="header_description">
                {
                  [
                    "",
                    "These are templates that you have saved on your Klaviyo account",
                    "View your selected Klaviyo Template ",
                  ][step]
                }
              </p>
            </div>
          }
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        {
          [
            <div className="create_klaviyo_campaign_box">
              <p>Click to proceed with your klaviyo campaign</p>
              <Button
                text="Proceed"
                loading={isCreatingKlaviyoCampaign}
                disabled={isCreatingKlaviyoCampaign}
                onClick={createKlaviyoCampaign}
              />
            </div>,
            <div className="table_container">
              <Table
                headers={tableHeaders}
                noRecord={klaviyoTemplates?.length === 0}
                isLoading={isFetchingKlaviyoTemplates}
              >
                {klaviyoTemplates?.map((item: any, i: any) => (
                  <tr key={i}>
                    <td>
                      <div className="template_box">
                        <p>{item?.attributes?.name}</p>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div className="action">
                        <DropDown toggler={<Icon name="dotsH" />}>
                          {contentOptions?.map((option: any, i: any) => (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                const campaign_body = `<div>${createdCampaign?.campaign_body}</div>`;
                                const newHtml = insertDivAsFirstChild(
                                  item?.attributes?.html,
                                  campaign_body
                                );
                                setSelectedTemplate({
                                  templateId: item?.id,
                                  ...item?.attributes,
                                  html: newHtml,
                                });
                                option?.action(item?.id);
                              }}
                            >
                              {option?.label}
                            </div>
                          ))}
                        </DropDown>
                      </div>
                    </td>
                  </tr>
                ))}
              </Table>
            </div>,
            <div className="template_preview">
              <div className="instruction_box">
                <div className="back" onClick={() => setStep(1)}>
                  <Icon name="arrowBack" />
                  <p>Back</p>
                </div>
                <p>
                  If you would like to make some high-level changes to the
                  images and colors, you will need to go to Klaviyo to make
                  those changes on this template and save to see the changes
                  here.
                </p>
                <Button
                  text={"Edit Template"}
                  onClick={() => {
                    window.open(
                      `https://www.klaviyo.com/email-template-editor/${selectedTemplate?.templateId}`,
                      "_blank"
                    );
                  }}
                />
              </div>
              <div className="preview_box">
                <iframe
                  srcDoc={selectedTemplate?.html}
                  title="Klaviyo Template"
                />

                <div className="button_box">
                  <Button
                    text="Cancel"
                    className="cancel_btn"
                    onClick={close}
                  />
                  <Button
                    text="Use Template"
                    className="use_btn"
                    loading={isAssigningTemplateToKlaviyoCampaign}
                    onClick={() =>
                      assignTemplateToKlaviyoCampaign(
                        selectedTemplate?.templateId || ""
                      )
                    }
                  />
                </div>
              </div>
            </div>,
          ][step]
        }
      </div>
    </Modal>
  );
};

export default KlaviyoTemplateModal;
