import { useState } from "react";
import { Modal } from "reactstrap";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../redux";

import SectionHeader from "../section-header";
import FormSelect from "../form-select";

import Icon from "../../assets/svg";
import Button from "../button";
import { saveCampaignEmailChannelAction } from "../../redux/dashboard/campaigns-slice";

type Props = {
  isOpen: boolean;
  close: any;
  selectedCampaign: any;
  setIsKlaviyoAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type Option = {
  value: string;
  label: string;
};

const SelectCampaignSenderModal = ({
  isOpen,
  close,
  selectedCampaign,
  setIsKlaviyoAuthModalOpen,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isSavingCampaignEmailChannel } = useSelector(
    (state: any) => state.campaignsSlice
  );
  const [selectedSender, setSelectedSender] = useState<Option | null>(null);

  const handleSenderChange = (option: Option) => {
    console.log("value:", option.value);
    setSelectedSender(option);
  };

  const handleContinue = async () => {
    const emailChannel = {
      id: selectedCampaign?.id,
      email_channel: selectedSender?.value,
    };
    localStorage.setItem("konvas_campaign_id", selectedCampaign?.id);
    await dispatch(saveCampaignEmailChannelAction(emailChannel));

    if (selectedSender?.value === "klaviyo") setIsKlaviyoAuthModalOpen(true);
    close();
  };

  return (
    <Modal className="select_campaign_sender_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">CHOOSE CAMPAIGN SENDER</div>}
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div className="select_sender">
          <p>Select a sender you would like to use for this campaign</p>
          <FormSelect
            name="sender"
            options={[
              { value: "", label: "Select one" },
              { value: "klaviyo", label: "Klaviyo" },
              { value: "konvasAI", label: "KonvasAI" },
            ]}
            placeholder="Select a sender"
            onChange={handleSenderChange}
          />
          <div className="button_box">
            <Button
              onClick={handleContinue}
              text="Continue"
              loading={isSavingCampaignEmailChannel}
              disabled={
                isSavingCampaignEmailChannel ||
                (selectedSender?.value !== "klaviyo" &&
                  selectedSender?.value !== "konvasAI")
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectCampaignSenderModal;
