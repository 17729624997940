import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";

import { useAppDispatch } from "../../redux";

import {
  createKlaviyoTemplateAction,
  getKlaviyoAuthUrlAction,
  verifyKlaviyoAuthCodeAction,
} from "../../redux/dashboard/klaviyo-slice";

import SectionHeader from "../section-header";
import Button from "../button";

import konvasIcon from "../../assets/svg/konvas-icon.svg";
import klaviyoIcon from "../../assets/svg/klaviyo-icon.svg";
import successImg from "../../assets/svg/success.svg";
import errorImg from "../../assets/svg/failure.svg";

import Icon from "../../assets/svg";
import Loader from "../loader";

import { insertDivAsFirstChild } from "../../utils/functions";
import { konvasKlaviyoTemplate } from "../../utils/appdata";

type Props = {
  isOpen: boolean;
  open: (isOpen: boolean) => void;
  getCampaignById: (id: string) => Promise<void>;
  setIsKlaviyoTemplateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const KlaviyoAuthModal = ({
  isOpen,
  open,
  getCampaignById,
  setIsKlaviyoTemplateModalOpen,
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);

  const { klaviyoAuthUrl, isVerifyingKlaviyoAuthCode } = useSelector(
    (state: any) => state.klaviyoSlice
  );

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);

  if (klaviyoAuthUrl && isOpen) {
    window.location.href = klaviyoAuthUrl;
  }

  useEffect(() => {
    const code = params.get("code");
    const state = params.get("state");
    console.log("mounted:", code, state);
    if (code && state) {
      if (!isOpen) {
        open(true);
        verifyKlaviyoAuthCode(code, state);
      }
    } else if (isOpen) {
      dispatch(getKlaviyoAuthUrlAction());
    } else {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const verifyKlaviyoAuthCode = async (code: string, state: string) => {
    try {
      const res = await dispatch(verifyKlaviyoAuthCodeAction({ code, state }));
      if (res?.success === true) {
        const campaign = JSON.parse(
          localStorage.getItem("__created_campaign") || "{}"
        );
        const campaign_body = `<div>${campaign?.campaign_body}</div>`;
        const htmlContent = insertDivAsFirstChild(
          konvasKlaviyoTemplate,
          campaign_body
        );
        console.log("htmlContent", htmlContent);
        const templateData = {
          data: {
            type: "template",
            attributes: {
              name: "Konvas AI Template",
              html: htmlContent,
              text: "Plain text version",
              editor_type: "CODE",
            },
          },
        };
        const res = await dispatch(createKlaviyoTemplateAction(templateData));
        if (res?.success === true) {
          const campaignId = localStorage.getItem("konvas_campaign_id");
          setStep(1);
          history.push("/campaigns");
          if (campaignId) await getCampaignById(campaignId);
        }
      }
    } catch (error) {
      console.log("auth-error:", error);
      history.push("/campaigns");
      setStep(2);
    }
  };

  const handleContinue = () => {
    open(false);
    setIsKlaviyoTemplateModalOpen(true);
  };

  return (
    <Modal className="klaviyo_auth_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">KLAVIYO INTEGRATION</div>}
          rightSide={
            <>
              <div className="icon" onClick={() => open(false)}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div className="auth_body">
          <div className="logo_box">
            <img src={konvasIcon} alt="" />
            <Icon name="transfer" />
            <img src={klaviyoIcon} alt="" />
          </div>
          <div>
            {
              [
                <div className="content">
                  {isVerifyingKlaviyoAuthCode ? (
                    <div className="loader_box">
                      <p>Verifying your klaviyo authentication...</p>
                      <Loader />
                    </div>
                  ) : (
                    <p>
                      You are being redirected to Klaviyo <br />
                      to complete your authentication
                    </p>
                  )}

                  <p className="wait">Please wait...</p>
                </div>,

                <div className="content">
                  <div>
                    <img src={successImg} alt="success-img" />
                    <p className="success_text">Successful!</p>
                  </div>
                  <p>
                    Your Konvas AI account has been integrated successfully with
                    your Klaviyo account.
                  </p>
                  <Button text="Continue" onClick={handleContinue} />
                </div>,

                <div className="content">
                  <div>
                    <img src={errorImg} alt="error-img" />
                    <p className="error_text">error!</p>
                  </div>

                  <p>
                    Your Konvas AI account integration with Klaviyo was
                    unsuccessful due to invalid data.
                  </p>
                  <div className="btn_box">
                    <Button text="Try Again?" className="try_again_btn" />
                    <Button text="Cancel" />
                  </div>
                </div>,
              ][step]
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default KlaviyoAuthModal;
