import { createSlice } from "@reduxjs/toolkit";
import { API_URLS } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError, handleFetchAPIError } from "../../utils/functions";

type Props = {
  isFetchingKlaviyoAuthUrl: boolean;
  klaviyoAuthUrl: string;
  klaviyoTemplates: any;
  klaviyoCampaign: any;
  estimatedRecipientsData: any;
  renderedKlaviyoTemplate: string;
  isFetchingKlaviyoTemplates: boolean;
  isCreatingKlaviyoTemplate: boolean;
  isVerifyingKlaviyoAuthCode: boolean;
  isRenderingKlaviyoTemplate: boolean;
  isFetchingKlaviyoCampaign: boolean;
  isCreatingKlaviyoCampaign: boolean;
  isAssigningTemplateToKlaviyoCampaign: boolean;
  isSchedulingKlaviyoCampaign: boolean;
  isEstimatingRecipients: boolean;
};
export const initialState: Props = {
  isFetchingKlaviyoAuthUrl: false,
  klaviyoAuthUrl: "",
  klaviyoTemplates: null,
  klaviyoCampaign: null,
  estimatedRecipientsData: null,
  renderedKlaviyoTemplate: "",
  isFetchingKlaviyoTemplates: false,
  isCreatingKlaviyoTemplate: false,
  isVerifyingKlaviyoAuthCode: false,
  isRenderingKlaviyoTemplate: false,
  isFetchingKlaviyoCampaign: false,
  isCreatingKlaviyoCampaign: false,
  isAssigningTemplateToKlaviyoCampaign: false,
  isSchedulingKlaviyoCampaign: false,
  isEstimatingRecipients: false,
};

// Slice
const slice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    setIsFetchingKlaviyoAuthUrl: (state, { payload }) => {
      state.isFetchingKlaviyoAuthUrl = payload;
    },
    getKlaviyoAuthUrlSuccess: (state, { payload }) => {
      state.klaviyoAuthUrl = payload;
    },

    setIsVerifyingKlaviyoAuthCode: (state, { payload }) => {
      state.isVerifyingKlaviyoAuthCode = payload;
    },
    setIsFetchingKlaviyoTemplates: (state, { payload }) => {
      state.isFetchingKlaviyoTemplates = payload;
    },
    getKlaviyoTemplatesSuccess: (state, { payload }) => {
      state.klaviyoTemplates = payload;
    },
    setIsCreatingKlaviyoTemplate: (state, { payload }) => {
      state.isCreatingKlaviyoTemplate = payload;
    },
    setIsRenderingKlaviyoTemplate: (state, { payload }) => {
      state.isRenderingKlaviyoTemplate = payload;
    },
    getRenderedKlaviyoTemplateSuccess: (state, { payload }) => {
      state.renderedKlaviyoTemplate = payload;
    },

    setIsFetchingKlaviyoCampaign: (state, { payload }) => {
      state.isFetchingKlaviyoCampaign = payload;
    },

    getKlaviyoCampaignByIdSuccess: (state, { payload }) => {
      state.klaviyoCampaign = payload;
    },

    setIsCreatingKlaviyoCampaign: (state, { payload }) => {
      state.isCreatingKlaviyoCampaign = payload;
    },

    getCreatedKlaviyoCampaignSuccess: (state, { payload }) => {
      state.klaviyoCampaign = payload;
    },

    setIsAssigningTemplateToKlaviyoCampaign: (state, { payload }) => {
      state.isAssigningTemplateToKlaviyoCampaign = payload;
    },

    getAssignedTemplateToKlaviyoCampaignSuccess: (state, { payload }) => {
      state.klaviyoCampaign = payload;
    },

    setIsSchedulingKlaviyoCampaign: (state, { payload }) => {
      state.isSchedulingKlaviyoCampaign = payload;
    },
    getScheduledKlaviyoCampaignSuccess: (state, { payload }) => {
      state.klaviyoCampaign = payload;
    },
    setIsEstimatingRecipients: (state, { payload }) => {
      state.isEstimatingRecipients = payload;
    },
    getEstimatedRecipientsSuccess: (state, { payload }) => {
      state.estimatedRecipientsData = payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  setIsFetchingKlaviyoAuthUrl,
  getKlaviyoAuthUrlSuccess,
  setIsVerifyingKlaviyoAuthCode,
  setIsFetchingKlaviyoTemplates,
  getKlaviyoTemplatesSuccess,
  setIsCreatingKlaviyoTemplate,
  setIsRenderingKlaviyoTemplate,
  getRenderedKlaviyoTemplateSuccess,
  setIsCreatingKlaviyoCampaign,
  getCreatedKlaviyoCampaignSuccess,
  setIsAssigningTemplateToKlaviyoCampaign,
  getAssignedTemplateToKlaviyoCampaignSuccess,
  setIsSchedulingKlaviyoCampaign,
  getScheduledKlaviyoCampaignSuccess,
  setIsEstimatingRecipients,
  getEstimatedRecipientsSuccess,
  setIsFetchingKlaviyoCampaign,
  getKlaviyoCampaignByIdSuccess,
} = slice.actions;

export const getKlaviyoAuthUrlAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingKlaviyoAuthUrl(true));

    const response = await fetch(API_URLS?.getKlaviyoAuthUrl);

    await handleFetchAPIError(response);

    const resp = await response.json();

    dispatch(getKlaviyoAuthUrlSuccess(resp?.auth_url));
    dispatch(setIsFetchingKlaviyoAuthUrl(false));

    return { success: true, auth_url: resp?.auth_url };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingKlaviyoAuthUrl(false));
  }
};

export const verifyKlaviyoAuthCodeAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsVerifyingKlaviyoAuthCode(true));

      const res = await fetch(API_URLS?.verifyKlaviyoAuthCode, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      await handleFetchAPIError(res);

      await res.json();
      // console.log("klaviyo-auth-success-response", resp.data);
      // dispatch(setAlert(true, "success", "klaviyo authentication sucessful"));
      dispatch(setIsVerifyingKlaviyoAuthCode(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
      dispatch(setIsVerifyingKlaviyoAuthCode(false));
      throw errorMessage;
    }
  };

export const getKlaviyoTemplatesAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingKlaviyoTemplates(true));

    const response = await fetch(API_URLS?.getKlaviyoTemplates);

    await handleFetchAPIError(response);

    const resp = await response.json();

    console.log("klaviyo-templates-success-response", resp?.data);

    dispatch(getKlaviyoTemplatesSuccess(resp?.data));
    dispatch(setIsFetchingKlaviyoTemplates(false));

    return { success: true, templates: resp?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingKlaviyoTemplates(false));
  }
};

export const createKlaviyoTemplateAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsCreatingKlaviyoTemplate(true));

      const res = await fetch(API_URLS?.createKlaviyoTemplate, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      await handleFetchAPIError(res);

      // const resp = await res.json();
      // console.log("klaviyo-created-template-success-response", resp.data);
      // dispatch(getCreatedKlaviyoTemplateSuccess(resp?.data));
      // dispatch(setAlert(true, "success", "klaviyo authentication sucessful"));
      dispatch(setIsCreatingKlaviyoTemplate(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsCreatingKlaviyoTemplate(false));
    }
  };

export const renderKlaviyoTemplateAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsRenderingKlaviyoTemplate(true));

      const res = await fetch(
        API_URLS?.renderKlaviyoTemplate(data?.templateId),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      await handleFetchAPIError(res);

      const resp = await res.json();
      console.log("klaviyo-rendered-success-response", resp.data);
      dispatch(getRenderedKlaviyoTemplateSuccess(resp?.data?.html));
      // dispatch(setAlert(true, "success", "klaviyo authentication sucessful"));
      dispatch(setIsRenderingKlaviyoTemplate(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsRenderingKlaviyoTemplate(false));
    }
  };

export const assignTemplateToKlaviyoCampaignAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsAssigningTemplateToKlaviyoCampaign(true));

      const res = await fetch(API_URLS?.assignTemplateToKlaviyoCampaign, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      await handleFetchAPIError(res);

      const resp = await res.json();
      console.log("klaviyo-assigned-template-success-response", resp?.data);
      dispatch(getAssignedTemplateToKlaviyoCampaignSuccess(resp?.data));
      dispatch(
        setAlert(
          true,
          "success",
          "Template has been successfully assigned to your campaign"
        )
      );
      dispatch(setIsAssigningTemplateToKlaviyoCampaign(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsAssigningTemplateToKlaviyoCampaign(false));
    }
  };

export const getKlaviyoCampaignByIdAction =
  (campaignId: string) => async (dispatch: any) => {
    try {
      dispatch(setIsFetchingKlaviyoCampaign(true));

      const response = await fetch(
        API_URLS?.getKlaviyoCampaignById(campaignId)
      );

      await handleFetchAPIError(response);

      const resp = await response.json();

      console.log("klaviyo-campaign-by-id-success-response", resp?.data);

      dispatch(getKlaviyoCampaignByIdSuccess(resp?.data));
      dispatch(setIsFetchingKlaviyoCampaign(false));

      return { success: true, campaign: resp?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsFetchingKlaviyoCampaign(false));
    }
  };

export const createKlaviyoCampaignAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsCreatingKlaviyoCampaign(true));

      const res = await fetch(API_URLS?.createKlaviyoCampaign, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      await handleFetchAPIError(res);

      const resp = await res.json();
      console.log("klaviyo-created-campaign-success-response", resp);
      dispatch(getCreatedKlaviyoCampaignSuccess(resp));
      // dispatch(setAlert(true, "success", "klaviyo authentication sucessful"));
      dispatch(setIsCreatingKlaviyoCampaign(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsCreatingKlaviyoCampaign(false));
    }
  };

export const estimateRecipientsAction =
  (campaignId: string) => async (dispatch: any) => {
    try {
      dispatch(setIsEstimatingRecipients(true));

      const response = await fetch(API_URLS?.estimateRecipients(campaignId));
      await handleFetchAPIError(response);
      const resp = await response.json();
      console.log("klaviyo-estimate-recipients-success-response", resp);
      dispatch(getEstimatedRecipientsSuccess(resp));
      dispatch(
        setAlert(true, "success", "campaign recipients estimated successfully!")
      );
      dispatch(setIsEstimatingRecipients(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsEstimatingRecipients(false));
    }
  };

export const scheduleKlaviyoCampaignSend =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsSchedulingKlaviyoCampaign(true));

      const res = await fetch(API_URLS?.scheduleKlaviyoCampaignSend, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      await handleFetchAPIError(res);

      const resp = await res.json();
      console.log("klaviyo-scheduled-campaign-success-response", resp);
      dispatch(getScheduledKlaviyoCampaignSuccess(resp));
      dispatch(setAlert(true, "success", "Campaign started successfully!"));
      dispatch(setIsSchedulingKlaviyoCampaign(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsSchedulingKlaviyoCampaign(true));
    }
  };
