import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import Button from "../button";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  confirmationText?: string;
  confirmationHeader?: string;
  confirmationAction?: JSX.Element;
  onSubmit: () => void;
  loading: boolean;
};

const ConfirmationModal = ({
  isOpen,
  close,
  confirmationHeader,
  confirmationText,
  confirmationAction,
  onSubmit,
  loading,
}: Props) => {
  return (
    <Modal className="confirmation_modal size_md" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={
            <p className="head_text">{confirmationHeader ?? "Confirmation"}</p>
          }
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <p className="confirmation_text">
          {confirmationText ||
            "Are you sure you want to proceed with this action?"}
        </p>

        {confirmationAction ?? (
          <Button text="Proceed" onClick={onSubmit} loading={loading} />
        )}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
