import { Modal } from "reactstrap";
import classNames from "classnames";

import SectionHeader from "../section-header";
import Button from "../button";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  campaignSender: string;
  action: () => void;
};

const CampaignSenderConfirmationModal = ({
  isOpen,
  close,
  campaignSender,
  action,
}: Props) => {
  return (
    <Modal className="campaign_sender_confirmation_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">Confirm Campaign Sender</div>}
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div className="content_box">
          <p>
            Are you sure you want to send this campaign with{" "}
            <strong
              className={classNames({
                konvas: campaignSender === "Konvas AI",
                klaviyo: campaignSender === "Klaviyo",
              })}
            >
              {campaignSender}
            </strong>{" "}
            as your chosen sender?
          </p>
          <div className="btn_box">
            <Button text="Cancel" onClick={close} className="btn_secondary" />
            <Button
              text="Confirm"
              onClick={() => {
                action();
                close();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CampaignSenderConfirmationModal;
