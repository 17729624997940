import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";

import { useAppDispatch } from "../../redux";

import Loader from "../loader";
import SectionHeader from "../section-header";

import Icon from "../../assets/svg";

import { getPersonalizedCampaignsByIdAction } from "../../redux/dashboard/campaigns-slice";

type Props = {
  isOpen: boolean;
  close: any;
  campaign: any;
};

type Campaign = {
  body: string;
  campaign_id: string;
  customer_email: string;
  customer_name: string;
  id: string;
  status: string;
  subject: string;
};

const CampaignMessagePreviewModal = ({ isOpen, close, campaign }: Props) => {
  const dispatch = useAppDispatch();
  const { isGettingPersonalizedCampaigns } = useSelector(
    (state: any) => state.campaignsSlice
  );

  // const [isLoading, setIsLoading] = useState(true);
  const [campaignMessages, setCampaignMessages] = useState<Campaign[]>([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    getpersonalizedCampaignMessages(campaign?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getpersonalizedCampaignMessages = (id: string) => {
    // setIsLoading(true);
    dispatch(getPersonalizedCampaignsByIdAction(id)).then((res: any) => {
      const data: any = res?.data;
      console.log("personalized-data", data);
      setCampaignMessages(data?.campaigns);
      // setIsLoading(false);
    });
  };

  const handleSlider = (direction: string) => {
    if (direction === "right")
      return setCurrentMessageIndex((prev) =>
        prev === campaignMessages?.length - 1 ? prev : prev + 1
      );

    setCurrentMessageIndex((prev) => (prev === 0 ? prev : prev - 1));
  };

  return (
    <Modal className="campaign_message_preview_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">PREVIEW</div>}
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        {isGettingPersonalizedCampaigns && campaignMessages.length === 0 ? (
          <Loader />
        ) : campaignMessages.length !== 0 ? (
          <>
            {" "}
            <p className="note">
              This preview contains personalized campaign message
              recommendations for each customer
            </p>
            <div className="campaign_body">
              <p className="heading">CAMPAIGN SUBJECT</p>
              <p className="subheading">
                {campaignMessages?.[currentMessageIndex]?.subject}
              </p>
              <div
                className="personalized_message"
                dangerouslySetInnerHTML={{
                  __html: campaignMessages?.[currentMessageIndex]?.body,
                }}
              ></div>
            </div>
            <div className="slider">
              <div className="icon" onClick={() => handleSlider("left")}>
                <Icon name="arrowCircleLeft" />
              </div>
              <p>
                {currentMessageIndex + 1} of {campaignMessages.length}
              </p>
              <div className="icon" onClick={() => handleSlider("right")}>
                <Icon name="arrowCircleRight" />
              </div>
            </div>
          </>
        ) : (
          <>No previews for this campaign</>
        )}
      </div>
    </Modal>
  );
};

export default CampaignMessagePreviewModal;
