export const onboardingQuestionsAndOptions = [
  {
    type: "single",
    name: "primary_marketing_goal",
    question: "What is your primary marketing goal?",
    options: [
      "Increase sales and revenue",
      "New Customers (Reduce Customer Acquisition Cost)",
      "Increase existing customer retention rate",
      "Increase product discoverability",
      "Other",
    ],
  },
  {
    type: "single",
    name: "biggest_ecommerce_challenge",
    question:
      "What is currently the biggest challenge in managing your e-commerce business?",
    options: [
      "Attracting new customers",
      "Converting visitors into paying customers",
      "Retaining existing customers and encouraging repeat purchases",
      "Managing Marketing Campaigns including copy development, personalization, and optimization.",
      "Other",
    ],
  },

  {
    type: "multiple",
    name: "marketing_channel",
    question:
      "Which marketing channel(s) do you use to engage your customers? (Select all that applies)",
    options: [
      "Email",
      "Facebook",
      "SMS",
      "Google Ads",
      "Instagram",
      "Tiktok",
      "Other",
    ],
  },
  {
    type: "single",
    name: "marketing_tool",
    question: "What do you currently use for email marketing? (if applicable)",
    options: [
      "Klaviyo",
      "Mailchimp",
      "Apollo.io",
      "Wunderkind",
      "None",
      "Other",
    ],
  },
  // {
  //   type: "single",
  //   name: "current_annual_revenue",
  //   question: "What is your current annual revenue range?",
  //   options: [
  //     "Less than $100k",
  //     "$100k - $499k",
  //     "$500k - $999k",
  //     "$1M - $4.9M",
  //     "$5M+",
  //   ],
  // },
];

interface BrandTags {
  tag: string;
  toolTipText?: string;
}

export const brandTags: BrandTags[] = [
  {
    tag: "Forward-thinking",
    toolTipText:
      "<p>Forward-thinking example: <br/> Step into the future of marketing with Konvas AI effortless personalization, powered by innovation! </p>",
  },
  {
    tag: "Eco-conscious",
    toolTipText:
      "<p>Eco-conscious example: <br/> Save time, save energy, and market smarter with Konvas AI, your partner in sustainable marketing solutions. </p>",
  },

  {
    tag: "Educational",
    toolTipText:
      "<p>Educational: <br/> Did you know that personalized campaigns improve engagement by over 50%? Konvas AI automates this for you. </p>",
  },

  {
    tag: "Classic",
    toolTipText:
      "<p>Classic tone example:<br/> Marketing made simple: Konvas AI automates personalization so you can focus on what matters most. </p>",
  },

  {
    tag: "Casual",
    toolTipText:
      "<p>Casual example:<br/> Campaign hyper Personalization? Easy peasy. Konvas AI takes care of it so you don’t have to. </p>",
  },

  {
    tag: "Positive",
    toolTipText:
      "<p>Positive example : <br/> Say goodbye to stress and hello to results! Konvas AI makes personalized marketing effortless. </p>",
  },

  {
    tag: "Sarcastic",
    toolTipText:
      "<p>Sarcastic tone example:<br/>Sure, you could spend hours doing the marketing personalization manually… or just let Konvas AI do the hard work for you. Your call! </p>",
  },

  {
    tag: "Encouraging",
    toolTipText:
      "<p>Encouraging example: <br/> We've got you! You don't have to do this alone! With Konvas AI, automating personalized marketing has never been easier.  </p>",
  },

  {
    tag: "Amusing",
    toolTipText:
      "<p>Amusing example: <br/> Why Konvas Ai? Because life is too short to send boring, one-size-fits-all campaigns. </p>",
  },

  {
    tag: "Straightforward/direct",
    toolTipText:
      "<p>Straightforward/direct example:<br/> Need a software that automates personalized marketing, Save time and Boost results?  That’s Konvas AI. </p>",
  },

  {
    tag: "Formal",
    toolTipText:
      "<p>Formal: <br/> Konvas AI offers a streamlined marketing solution to automate hyper-personalized campaigns, enabling you to save time and achieve better customer engagement, and reduced churn. </p>",
  },

  {
    tag: "Inspiring",
    toolTipText:
      "<p>Inspiring example: <br/> Imagine a world where marketing is simple and impactful, zero stress, zero hassle. With Konvas AI, that world is yours.  </p>",
  },

  {
    tag: "Friendly",
    toolTipText:
      "<p>Friendly example : <br/> Hey there! Konvas AI is here to save you time and help you connect with your customers like never before with hyper personalization campaigns. </p>",
  },
];

export const tag_style_one = [
  "Forward-thinking",
  "Classic",
  "Positive",
  "Straightforward/direct",
  "Formal",
];
export const tag_style_two = ["Eco-conscious"];
export const tag_style_three = [
  "Educational",
  "Casual",
  "Encouraging",
  "Friendly",
];
export const tag_style_four = ["Sarcastic", "Amusing", "Inspiring"];

export const brandTagStyleClassNames = [
  "tag_style_1",
  "tag_style_2",
  "tag_style_3",
  "tag_style_4",

  "tag_style_1",
  "tag_style_2",
  "tag_style_3",
  "tag_style_4",

  "tag_style_1",
  "tag_style_2",
  "tag_style_3",
  "tag_style_4",

  "tag_style_1",
  "tag_style_2",
  "tag_style_3",
  "tag_style_4",

  "tag_style_1",
  "tag_style_2",
  "tag_style_3",
  "tag_style_4",

  "tag_style_1",
  "tag_style_2",
  "tag_style_3",
  "tag_style_4",

  "tag_style_1",
  "tag_style_2",
  "tag_style_3",
  "tag_style_4",
];

export const writingApproach = [
  { type: "Storytelling Approach" },
  { type: "Interactive Approach" },
  { type: "Curiosity Approach" },
  { type: "Fact-Driven Approach" },
  { type: "Authority Approach" },
  { type: "Exclusivity Approach" },
  { type: "Aspirational Approach" },
];

export const konvasKlaviyoTemplate =
  "<!Doctype html> <html><body></body></html>";
